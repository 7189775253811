import React, { useState } from "react";
import FormSuccess from "./FormSuccess";
import "../../css/contact_form.css";
import FormSignUp from "./FormSignUP";
import Spaceship from "../../assets/img/spaceship.svg";

const ContactForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formSubmitted = () => {
    setIsSubmitted(true);
  };
  const formClose = () => {
    setIsSubmitted(true);
  };

  return (
    <div className="contact-container">
      <div className="form-wrapper">
        <div className="form-wrapper--left">
          {" "}
          <img
            src={Spaceship}
            alt="spaceship"
            className="spaceship-image left"
          />
        </div>
        <div className="form-wrapper--right">
          {!isSubmitted ? (
            <FormSignUp formSubmitted={formSubmitted} />
          ) : (
            <FormSuccess formClose={formClose}/>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
