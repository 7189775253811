import React from "react";
import { Link } from "react-scroll";
import "../../css/header.css";


export default function Header() {
  return (
    <div className="header">
      <div className="header-wrapper">
        <div className="header_heading--main">ozzy</div>
        <div className="header_heading--sub">Where websites happens</div>
        <Link
          to="projects-line"
          href="projects-line"
          className="btn btn--white"
        >
          discover my websites
        </Link>
      </div>
    </div>
  );
}
