import React, { Component } from "react";
import Role from "../education/role/Role.jsx";
import "../../css/experience.css";
import experienceList from "../../config/experience.js";

class Experience extends Component {
 

  render() {
    return (
      <div className="experience-container">
        <h1
          className="experience-title"
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          experience
        </h1>
        <br />
        <div
          className="expereience-tree-container experienceList"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <Role data={experienceList[0]} sender={"experience"} />
          <Role data={experienceList[1]} sender={"experience"} />
          <Role data={experienceList[2]} sender={"experience"} />
          <Role data={experienceList[3]} sender={"experience"} />
          <Role data={experienceList[4]} sender={"experience"} />
          <Role data={experienceList[5]} sender={"experience"} />
        </div>
      </div>
    );
  }
}

export default Experience;
