import { useState, useEffect } from "react";
import { db } from "../../firebase/firebase";

const FormLogic = (formSubmitted, validate) => {
  const [inputValues, setInputData] = useState({
    username: "",
    useremail: "",
    userphone: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const saveContact = async () => {
    await db
      .collection("contacts")
      .add(inputValues)
      .catch((err) => {
        console.error("ERROR: ", err);
      })
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(inputValues));
    setIsSubmitted(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputValues, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitted) {
      try {
        saveContact(inputValues);
      } catch (err) {
        console.error("Error in UseEffect :", err);
      }
      formSubmitted();
    }
  });

  return { handleChange, inputValues, handleSubmit, errors };
};

export default FormLogic;
