import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "../../css/arrowup.css";

export default function ScroolToTop() {
  const [visible, setVisability] = useState(false);
  
  const handleScroll=() => {
    if (window.scrollY > 250) setVisability(true);
    else setVisability(false);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return ()=> window.removeEventListener("scroll", handleScroll); 
  },[]);

  return (
    <div className="return-to-btn">
      {!visible ? null : (
        <Link to="header" className="arrow-wrapper scroll-to-top">
          <i className="fas fa-angle-double-up fa-6x"></i>
        </Link>
      )}
    </div>
  );
}
