import React from "react";
import FormLogic from "./formLogic";
import Validation from "./formValidation";
import "../../css/formsign.css";

export default function FormSignUp(props) {
  const { handleChange, inputValues, handleSubmit, errors } = FormLogic(
    props.formSubmitted,
    Validation
  );

  return (
    <div className="form-container">
      <form className="form" onSubmit={handleSubmit}>
        <h1 className="form-title">Let's talk</h1>
        <div className="form-inputs">
          <label htmlFor="userFullName" className="form-label username">
            Full Name
          </label>
          <input
            type="text"
            name="username"
            className="form-input"
            placeholder="Enter your full name"
            onChange={handleChange}
            values={inputValues.username}
          />
          {errors.username && <p>{errors.username}</p>}
          <label htmlFor="userEmail" className="form-label email">
            Email
          </label>
          <input
            name="useremail"
            className="form-input"
            placeholder="Enter your email"
            onChange={handleChange}
            values={inputValues.useremail}
          />
          {errors.useremail && <p>{errors.useremail}</p>}
          <label htmlFor="userphone" className="form-label phone">
            Phone
          </label>
          <input
            type="text"
            name="userphone"
            className="form-input"
            placeholder="Enter your phone"
            onChange={handleChange}
            values={inputValues.userphone}
          />
          {errors.userphone && <p>{errors.userphone}</p>}
        </div>
        <button className="form-input-btn" type="submit">
          send
        </button>
      </form>
    </div>
  );
}
