export default function validation(values) {
  let errors = {};
  let regexEmail = /^[\w]+@([\w-]+\.)+[\w-]{2,4}$/;
  let regexPhone = /^\d{10}$/;
  
  if (!values.username.trim()) {
    errors.username = "User name required";
  }

  if (!values.useremail) {
    errors.useremail = "Email required";
  } else if (!regexEmail.test(values.useremail)) {
    errors.useremail = "Email address is invalid";
  }

  if (!values.userphone) {
    errors.userphone = "Phone number required";
  } else if (!regexPhone.test(values.userphone)) {
    errors.userphone = "Phone number invalid";
  }

  return errors;
}
