import React ,{useEffect}from "react";
import Astronut from "../../assets/img/astronaut.svg";
import "../../css/formsuccess.css"
const FormSuccess = (props) => {
  useEffect(() => {
    props.formClose()
  })

  return (
    <div className="approval-container">
      <h1 className="approval-title">Great! I will return to you soon</h1>
      <img
        src={Astronut}
        alt="astronut"
        className="astronut-image"
      ></img>
    </div>
  );
};

export default FormSuccess;
