import React, { useState } from "react";
import "../../../css/role.css";

export default function Role(props) {
  const [description_status, setdescription] = useState(true);

  return (
    <div className="role-container">
      <div className="img-wrapper">
        <img
          src={props.data.img}
          alt={props.data.title}
          className="company-img"
          id={props.sender === "experience" ? props.data.company_name : null}
        />
      </div>
      <div className="content-wrapper">
        <h2 className="year">{props.data.year}</h2>
        &nbsp; &nbsp;
        <h2 className="role__title">{props.data.title}</h2>
        &nbsp; &nbsp;
        <div className="btn-container">
          <a
            href={`${props.data.link}`}
            className="course-link"
            target="_blank"
            rel="noreferrer"
          >
            {props.sender === "education" ? (
              <i className="fas fa-link"></i>
            ) : (
              ""
            )}
          </a>
          <button
            className="btn-description"
            onClick={() => {
              setdescription(!description_status);
            }}
          >
            <i className="fas fa-caret-square-down"></i>
          </button>
        </div>
        {!description_status ? (
          <div className="course-description">
            {props.data.description.map((d, k) => (
              <div className="sentencee" key={k}>
                {props.sender === "experience" ? (
                  <i className="fas fa-circle" />
                ) : null}
                <p className="description-sentece">{d}</p>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
