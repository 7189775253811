import React, { Component } from "react";
import "../../css/about.css";
import AOS from "aos";
import ProfileImage from "../../assets/img/orench.png";

class About extends Component {
  render() {
    AOS.init();
    return (
      <div className="about-container">
        <div
          className="about-content"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <h2 className="about-content__header">ALL ABOUT ME</h2>
          &nbsp;
          <p className="about-content__paragraph">
            <p>
              I am a dedicated hard working team player, ambitious and diligent
              with a passion for learning and adopting new technologies. I am
              always looking for ways to expand my knowledge and improve my
              skills by creating applications.
            </p>
            &nbsp;
            {/* <p>
              A motivated developer with a keen eye for detail, utilizing
              cutting edge technologies solving large scale problems.
            </p>{" "}
            &nbsp; */}
            <p>
              Detail oriented with the ability to see the bigger picture, I was
              a Co-founder of a gaming startup and an integral part of the
              leading team. I enjoy being a part of an awesome and interesting
              company, always dedicated to the collective goal.
            </p>
          </p>
        </div>
        <img
          src={ProfileImage}
          alt="profile-img"
          className="profile-img"
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        />
      </div>
    );
  }
}
export default About;
