import React, { useState, useEffect } from "react";
import Header from "./components/header/Header";
import Explore from "./components/explore/ExploreWebsites";
import About from "./components/about/About";
import DropdownMenu from "./components/dropdown_menu/Menu";
import Hamburger from "./components/hamburger/Hamburger";
import Skills from "./components/skills/Skill";
import Education from "./components/education/Education";
import Experience from "./components/experience/Experience";
import LandingPage from "./components/start_page/Landing";
import ContactForm from "./components/contact/ContactForm";
import ScroolToTop from "./components/scrollToTop/buttonup";
import "./App.css";

export default function App() {
  const [btnFlage, setbtnFlage] = useState(false);
  const [loading, setloading] = useState(false);

  let onClickMenuButton = () => {
    setbtnFlage(!btnFlage);
  };

  useEffect(() => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 8000);
  }, []);

  return (
    <div className="main">
        {loading ? (
          <LandingPage />
        ) : (
          <div className="main-container">
            <div className="section section--header">
              <Header />
            </div>
            <hr className="line-section" id="about-line" />
            <div className="section section--about_me" id="about">
              <About />
            </div>
            <hr className="line-section" id="education-line" />
            <div className="section section--education" id="education">
              <Education />
            </div>
            <hr className="line-section" id="skills-line" />
            <div className="section section--skills" id="skills">
              <Skills />
            </div>
            <hr className="line-section" id="experience-line" />
            <div className="section section--experience" id="experience">
              <Experience />
            </div>
            <hr className="line-section" id="projects-line" />
            <div className="section section--websites" id="projects">
              <Explore />
            </div>
            <hr className="line-section" id="contact-line" />
            <div className="section section--contact" id="contact">
              <ContactForm />
            </div>
            <DropdownMenu
              class={btnFlage ? "open" : "close"}
              onClickMenuButton={onClickMenuButton}
            />
            <Hamburger
              onClickMenuButton={onClickMenuButton}
              class={btnFlage ? "X" : "G"}
            />
            <ScroolToTop />
          </div>
        )}
    </div>
  );
}
