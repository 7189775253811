import React from "react";
import "../../css/hamburger.css";

export default function Hamburger(props) {
  let changeStatus = () => {
    props.onClickMenuButton();
  };

  return (
    <div className="hamburger_wrapper">
      <button
        className={`hamburger_btn ${props.class}`}
        onClick={changeStatus}
      >
        <div className="hamburger_btn__line"></div>
      </button>
    </div>
  );
}
