import IAI from "../assets/img/iai.jpg";
import IDF from "../assets/img/idf.png";
import Quadro from "../assets/img/quadro.jpg";
import Seven from "../assets/img/7is.png";
import Funatix from "../assets/img/funatix.png";
import Oren from "../assets/img/orenlogo.png";

const experienceList = [
  {
    img: Oren,
    company_name: "",
    title: "Analyst, self-employed",
    description: [
      "Raising and managing investments of ~2M in the US stock market",
      "Making trading decisions in the short/medium term through technical and fundamental analysis of the markets",
      "Performing thorough research of markets and businesses in various industries to find opportunities for investment",
    ],
    year: "2020-2021",
  },
  {
    img: Seven,
    company_name: "seven",
    title: "Strategic business consultant, 7I’s",
    description: [
      "Leading cross-organizational processes improvement in various domains such as insurance, retail and finance",
      "Locating bottlenecks and identifying opportunities to optimize processes and gain efficiency to better outputs",
      "Developing recommendations and action plans for implementation",
      "Developing projects methodology and define success indicators & KPIs",
      "Presenting project status and results to executive management",
    ],
    year: "2019-2020",
  },
  {
    img: Funatix,
    company_name: "funatix",
    title: "Co-founder & Business development,  Proyoli Gaming/ Funatix Club ",
    description: [
      "Leading a startup company with 7 employees",
      "Writing & detail design, characterization, mockups",
      "Developing new features and monitoring performance",
      "Analysis of product users and leading actions to optimize revenue generation",
      "Introducing product capabilities and investment fundraising",
      "The company developed Social Sport Game (www.Real-Manager.club)",
    ],
    year: "2016-2018",
  },
  {
    img: IAI,
    company_name: "iai",
    title: "Supply chains, MRP controller (student), Israel Aerospace, Elta",
    description: [
      "Managing production schedules and handling material ",
      "Supervising interdepartmental activities with quality assurance and manufacturing",
      "Administering personnel and equipment and handling material supply and demands",
    ],
    year: "2014-2016",
  },
  {
    img: Quadro,
    company_name: "quadro",
    title: "quadro technology Ltd , project manager & safty inspector ",
    description: [
      "Managing teams with the ability to respond to frequently changes",
      "Creating work plan in security classified projects in the field of ammunition & explosive",
      "Monitoring processes & Inspection in Israel & abroad (Angola)",
      "Training army officers (Nigeria)",
    ],
    year: "2008-2011",
  },
  {
    img: IDF,
    company_name: "idf",
    title: "MILITARY SERVICE , Sayeret Yael, Yahalom, Captain in reserve",
    description: [
      "Finished service as deputy company commander in Yahalom (special unit of the combat Engineering Corps), commanded 40 soldiers",
    ],
    year: "2003-2008",
  },
];

export default experienceList;
