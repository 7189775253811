import React from "react";
import "../../css/start_page.css";

export default function Landing_page() {
  return (
    <div className="Landing_page-container">
      <div className="loading">
        <span className="letter">O</span>
        <span className="letter">R</span>
        <span className="letter">E</span>
        <span className="letter">N</span>
        <span className="letter">&nbsp;</span>
        <span className="letter">C</span>
        <span className="letter">H</span>
        <span className="letter">A</span>
        <span className="letter">K</span>
        <span className="letter">Y</span>
      </div>
    </div>
  );
}
