import React from "react";
import "../../css/explore_websites.css";
import css from "../../assets/technology/css.png";
import express from "../../assets/technology/express.svg";
import html from "../../assets/technology/html.png";
import js from "../../assets/technology/js.png";
import mongodb from "../../assets/technology/mongodb.png";
import nodejs from "../../assets/technology/nodejs.png";
import react from "../../assets/technology/react.png";
import ts from "../../assets/technology/typescript.svg";
import mobx from "../../assets/technology/mobx.png";
import scss from "../../assets/technology/scss.png";
import sql from "../../assets/technology/sql.png";
import heroku from "../../assets/technology/heroku.png";
import firebase from "../../assets/technology/firebase.png";
import webpack from "../../assets/technology/webpack.png";

export default function Explore() {
  return (
    <div className="proj bricks-container">
      <h1 className="title">
        <span className="title-main">my projects</span>
      </h1>
      <div
        className="proj__brick proj1"
        data-aos="fade-down-right"
        data-aos-duration="700"
        data-aos-easing="ease-in-out"
      >
        <div className="proj__brick--front proj__brick-side"></div>
        <div className="proj__brick--back proj__brick-side">
          <h2 className="proj-technology-description">
            Customer relationship managment
          </h2>
          <hr className="line-seperator" />
          <div className="tech-wrapper">
            <img src={js} alt="tecj_img" className="js-img webimg" />
            <img src={html} alt="tecj_img" className="html-img webimg" />
            <img src={css} alt="tecj_img" className="css-img webimg" />
            <img src={nodejs} alt="tecj_img" className="nodejs-img webimg" />
            <img src={express} alt="tecj_img" className="express-img webimg" />
            <img src={mobx} alt="tecj_img" className="mobx-img webimg" />
            <img src={react} alt="tecj_img" className="react-img webimg" />
            <img src={sql} alt="tecj_img" className="sql-img webimg" />
          </div>
          <div className="links-wrapper">
            <a
              href="https://github.com/oreninbar/CRM-REACT-MOBX"
              target="_blank"
              rel="noreferrer"
              className="project-link"
            >
              {" "}
              <i className="fas fa-link"></i>
            </a>
          </div>
        </div>
      </div>
      <div
        className="proj__brick proj2"
        data-aos="fade-up-right"
        data-aos-duration="1000"
        data-aos-easing="running"
      >
        <div className="proj__brick--front proj__brick-side"></div>
        <div className="proj__brick--back proj__brick-side">
          <h2 className="proj-technology-description">Login Form</h2>
          <hr className="line-seperator" />{" "}
          <div className="tech-wrapper">
            <img src={js} alt="tecj_img" className="js-img webimg" />
            <img src={html} alt="tecj_img" className="html-img webimg" />
            <img src={css} alt="tecj_img" className="css-img webimg" />
            <img src={react} alt="tecj_img" className="react-img webimg" />
            <img src={nodejs} alt="tecj_img" className="nodejs-img webimg" />
            <img src={express} alt="tecj_img" className="express-img webimg" />
            <img src={mongodb} alt="tecj_img" className="mongodb-img webimg" />
            <img src={mobx} alt="tecj_img" className="mobx-img webimg" />
            <img src={scss} alt="tecj_img" className="scss-img webimg" />
          </div>
          <div className="links-wrapper"></div>
          <a
            href="https://github.com/oreninbar/loginForm.git"
            target="_blank"
            rel="noreferrer"
            className="project-link"
          >
            {" "}
            <i className="fas fa-link"></i>
          </a>
        </div>
      </div>
      <div
        className="proj__brick proj3"
        data-aos="fade-up"
        data-aos-duration="900"
        data-aos-easing="ease-in-out"
      >
        <div className="proj__brick--front proj__brick-side"></div>
        <div className="proj__brick--back proj__brick-side">
          <h2 className="proj-technology-description">Movie selector</h2>
          <hr className="line-seperator" />
          <div className="tech-wrapper">
            <img src={js} alt="tecj_img" className="js-img webimg" />
            <img src={html} alt="tecj_img" className="html-img webimg" />
            <img src={scss} alt="tecj_img" className="scss-img webimg" />
            <img src={nodejs} alt="tecj_img" className="nodejs-img webimg" />
            <img src={express} alt="tecj_img" className="express-img webimg" />
            <img src={mobx} alt="tecj_img" className="mobx-img webimg" />
            <img src={heroku} alt="tecj_img" className="heroku-img webimg" />
            <img src={react} alt="tecj_img" className="react-img webimg" />
          </div>
          <div className="links-wrapper">
            <a
              href="https://github.com/oreninbar/movix"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              {" "}
              <i className="fab fa-github-square"></i>
            </a>
            <a
              href="https://cinemamovix.herokuapp.com/"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              {" "}
              <i className="fas fa-link"></i>
            </a>
          </div>
        </div>
      </div>

      <div
        className="proj__brick proj4"
        data-aos="fade-left"
        data-aos-duration="900"
        data-aos-easing="ease-in-out"
      >
        <div className="proj__brick--front proj__brick-side"></div>
        <div className="proj__brick--back proj__brick-side">
          <h2 className="proj-technology-description">Paycheck calculator</h2>
          <hr className="line-seperator" />
          <div className="tech-wrapper">
            <img src={js} alt="tecj_img" className="js-img webimg" />
            <img src={react} alt="tecj_img" className="react-img webimg" />
            <img src={nodejs} alt="tecj_img" className="nodejs-img webimg" />
            <img src={mobx} alt="tecj_img" className="mobx-img webimg" />
            <img src={scss} alt="tecj_img" className="scss-img webimg" />
            <img src={html} alt="tecj_img" className="html-img webimg" />
            <img src={css} alt="tecj_img" className="css-img webimg" />
            <img
              src={firebase}
              alt="tecj_img"
              className="firebase-img webimg"
            />
          </div>
          <div className="links-wrapper">
            <a
              href="https://github.com/oreninbar/paycheck-calculators"
              target="_blank"
              rel="noreferrer"
              className="project-link"
            >
              {" "}
              <i className="fab fa-github-square"></i>
            </a>
            <a
              href="https://netsalary.co.il"
              target="_blank"
              rel="noreferrer"
              className="project-link"
            >
              {" "}
              <i className="fas fa-link"></i>
            </a>
          </div>
        </div>
      </div>

      <div
        className="proj__brick proj5"
        data-aos="fade-down"
        data-aos-offset="100"
        data-aos-duration="900"
        data-aos-easing="running"
      >
        <div className="proj__brick--front proj__brick-side"></div>
        <div className="proj__brick--back proj__brick-side">
          <h2 className="proj-technology-description">Self website</h2>
          <hr className="line-seperator" />
          <div className="tech-wrapper">
            <img src={react} alt="tecj_img" className="react-img webimg" />
            <img src={js} alt="tecj_img" className="js-img webimg" />
            <img src={nodejs} alt="tecj_img" className="nodejs-img webimg" />
            <img src={mobx} alt="tecj_img" className="mobx-img webimg" />
            <img src={scss} alt="tecj_img" className="scss-img webimg" />
            <img src={html} alt="tecj_img" className="html-img webimg" />
            <img src={css} alt="tecj_img" className="css-img webimg" />
          </div>
          <div className="links-wrapper"></div>
          <a
            href="https://github.com/oreninbar/ozzy"
            target="_blank"
            rel="noreferrer"
            className="project-link"
          >
            {" "}
            <i className="fas fa-link"></i>
          </a>
        </div>
      </div>
      <div
        className="proj__brick proj6"
        data-aos="fade-up-left"
        data-aos-offset="100"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <div className="proj__brick--front proj__brick-side"></div>
        <div className="proj__brick--back proj__brick-side">
          <h2 className="proj-technology-description">ATM</h2>
          <hr className="line-seperator" />
          <div className="tech-wrapper">
            <img src={react} alt="tecj_img" className="react-img webimg" />
            <img src={js} alt="tecj_img" className="js-img webimg" />
            <img src={nodejs} alt="tecj_img" className="nodejs-img webimg" />
            <img src={express} alt="tecj_img" className="express-img webimg" />
            <img src={mongodb} alt="tecj_img" className="mongodb-img webimg" />
            <img src={mobx} alt="tecj_img" className="mobx-img webimg" />
            <img src={html} alt="tecj_img" className="html-img webimg" />
            <img src={css} alt="tecj_img" className="css-img webimg" />
          </div>
          <div className="links-wrapper"></div>
          <a
            href="https://github.com/oreninbar/ATM"
            target="_blank"
            rel="noreferrer"
            className="project-link"
          >
            {" "}
            <i className="fas fa-link"></i>
          </a>
        </div>
      </div>
      <div
        className="proj__brick proj7"
        data-aos="fade-up-right"
        data-aos-offset="50"
        data-aos-duration="900"
        data-aos-easing="ease-in-out"
      >
        <div className="proj__brick--front proj__brick-side"></div>
        <div className="proj__brick--back proj__brick-side">
          <h2 className="proj-technology-description">OZZYGAMES</h2>
          <hr className="line-seperator" />
          <div className="tech-wrapper">
            <img src={js} alt="tecj_img" className="js-img webimg" />
            <img src={nodejs} alt="tecj_img" className="nodejs-img webimg" />
            <img src={express} alt="tecj_img" className="express-img webimg" />
            <img src={mongodb} alt="tecj_img" className="mongodb-img webimg" />
            <img src={mobx} alt="tecj_img" className="mobx-img webimg" />
            <img src={scss} alt="tecj_img" className="scss-img webimg" />
            <img src={react} alt="tecj_img" className="react-img webimg" />
            <img src={webpack} alt="tecj_img" className="webpack-img webimg" />
          </div>
          <div className="links-wrapper"></div>
          <a
            href="https://github.com/oreninbar/ozzygames"
            target="_blank"
            rel="noreferrer"
            className="project-link"
          >
            {" "}
            <i className="fas fa-link"></i>
          </a>
        </div>
      </div>
      <div
        className="proj__brick proj8"
        data-aos="fade-up"
        data-aos-offset="50"
        data-aos-duration="800"
        data-aos-easing="ease-in-out"
      >
        <div className="proj__brick--front proj__brick-side"></div>
        <div className="proj__brick--back proj__brick-side">
          <h2 className="proj-technology-description">Chat</h2>
          <hr className="line-seperator" />
          <div className="tech-wrapper">
            <img src={js} alt="tecj_img" className="js-img webimg" />
            <img src={react} alt="tecj_img" className="react-img webimg" />
            <img src={nodejs} alt="tecj_img" className="nodejs-img webimg" />
            <img src={scss} alt="tecj_img" className="scss-img webimg" />
            <img src={express} alt="tecj_img" className="express-img webimg" />
            {/* <img src={socket} alt="tecj_img" className="socket-img webimg" /> */}
            <img src={mobx} alt="tecj_img" className="mobx-img webimg" />
            <img src={html} alt="tecj_img" className="html-img webimg" />
          </div>
          <div className="links-wrapper"></div>
          <a
            href="https://github.com/oreninbar/Chat"
            target="_blank"
            rel="noreferrer"
            className="project-link"
          >
            {" "}
            <i className="fas fa-link"></i>
          </a>
        </div>
      </div>
      <div
        className="proj__brick proj9"
        data-aos="fade-up-right"
        data-aos-offset="50"
        data-aos-duration="900"
        data-aos-easing="ease-in-out"
      >
        <div className="proj__brick--front proj__brick-side"></div>
        <div className="proj__brick--back proj__brick-side">
          <h2 className="proj-technology-description">HANGMAN</h2>
          <hr className="line-seperator" />
          <div className="tech-wrapper">
            <img src={js} alt="tecj_img" className="js-img webimg" />
            <img src={html} alt="tecj_img" className="html-img webimg" />
            <img src={nodejs} alt="tecj_img" className="nodejs-img webimg" />
          </div>
          <div className="links-wrapper"></div>

          <a
            href="https://github.com/oreninbar/ATM"
            target="_blank"
            rel="noreferrer"
            className="project-link"
          >
            {" "}
            <i className="fas fa-link"></i>
          </a>
        </div>
      </div>
      <div
        className="proj__brick proj10"
        data-aos="fade-up-left"
        data-aos-offset="50"
        data-aos-duration="900"
        data-aos-easing="ease-in-out"
      >
        <div className="proj__brick--front proj__brick-side"></div>
        <div className="proj__brick--back proj__brick-side">
          <h2 className="proj-technology-description">Ecommerce</h2>
          <hr className="line-seperator" />
          <div className="tech-wrapper">
            <img src={js} alt="tecj_img" className="js-img webimg" />
            <img src={nodejs} alt="tecj_img" className="nodejs-img webimg" />
            <img src={express} alt="tecj_img" className="express-img webimg" />
            <img src={mongodb} alt="tecj_img" className="mongodb-img webimg" />
            <img src={mobx} alt="tecj_img" className="mobx-img webimg" />
            <img src={ts} alt="tecj_img" className="ts-img webimg" />
            <img src={react} alt="tecj_img" className="react-img webimg" />
            <img src={scss} alt="tecj_img" className="scss-img webimg" />
          </div>
          <div className="links-wrapper"></div>
          <a
            href="https://github.com/oreninbar/EcommerceClothing"
            target="_blank"
            rel="noreferrer"
            className="project-link"
          >
            {" "}
            <i className="fas fa-link"></i>
          </a>
        </div>
      </div>
      <div
        className="proj__brick proj11"
        data-aos="fade-up-left"
        data-aos-offset="50"
        data-aos-duration="900"
        data-aos-easing="ease-in-out"
      >
        <div className="proj__brick--front proj__brick-side"></div>
        <div className="proj__brick--back proj__brick-side">
          <h2 className="proj-technology-description">Order List</h2>
          <hr className="line-seperator" />
          <div className="tech-wrapper">
            <img src={js} alt="tecj_img" className="js-img webimg" />
            <img src={nodejs} alt="tecj_img" className="nodejs-img webimg" />
            <img src={react} alt="tecj_img" className="react-img webimg" />
            <img src={css} alt="tecj_img" className="css-img webimg" />
            <img src={html} alt="tecj_img" className="html-img webimg" />
          </div>
          <div className="links-wrapper"></div>
          <a
            href="https://github.com/oreninbar/delawvery-assignment"
            target="_blank"
            rel="noreferrer"
            className="project-link"
          >
            {" "}
            <i className="fas fa-link"></i>
          </a>
        </div>
      </div>
      <div
        className="proj__brick proj12"
        data-aos="fade-up-left"
        data-aos-offset="50"
        data-aos-duration="900"
        data-aos-easing="ease-in-out"
      >
        <div className="proj__brick--front proj__brick-side"></div>
        <div className="proj__brick--back proj__brick-side">
          <h2 className="proj-technology-description">Investment manager</h2>
          <hr className="line-seperator" />
          <div className="tech-wrapper">
            <img src={js} alt="tecj_img" className="js-img webimg" />
            <img src={nodejs} alt="tecj_img" className="nodejs-img webimg" />
            <img src={express} alt="tecj_img" className="express-img webimg" />
            <img src={firebase} alt="tecj_img" className="mongodb-img webimg" />
            <img src={ts} alt="tecj_img" className="ts-img webimg" />
            <img src={mobx} alt="tecj_img" className="mobx-img webimg" />
            <img src={scss} alt="tecj_img" className="scss-img webimg" />
            <img src={react} alt="tecj_img" className="react-img webimg" />
          </div>
          <div className="links-wrapper"></div>
          <a
            href="https://github.com/oreninbar/stockey"
            target="_blank"
            rel="noreferrer"
            className="project-link"
          >
            {" "}
            <i className="fas fa-link"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
